import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "form-group relative entity-selector",
  style: {"width":"270px","margin-right":"5px"}
}
const _hoisted_2 = { class: "form-label" }
const _hoisted_3 = { class: "form-group relative d-flex flex-row align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["date-range-picker", {'d-flex': _ctx.display === 'inline', 'flex-row': _ctx.display === 'inline'}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.mdl,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mdl) = $event)),
          filterable: "",
          multiple: _ctx.multiple,
          placeholder: "Select",
          onChange: _cache[1] || (_cache[1] = (e) => _ctx.changed(e))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (o) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: o.v,
                label: o.t,
                value: o.v
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "multiple"])
      ])
    ])
  ], 2))
}