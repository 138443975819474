
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'OptionSelector',
    props: {
        modelValue: {
            default: [],
            required: true
        },
        label: {
            default: null,
            type: String,
            required: true
        },
        options: {
            default: [],
            required: true
        },
        multiple: {
            default: false,
            required: false
        },
    },
    methods: {
        changed(v) {
            this.$emit("changed", v);
        }
    },
    computed: {
        mdl: {
            get(){
                return this.modelValue;
            },
            set(v){
                this.$emit('update:modelValue', v);
            }
        }
    },
    data(){
        return {
            showArchive: false
        };
    },
});
