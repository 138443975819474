
import { defineComponent, onMounted } from 'vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { setCurrentPageButtons } from '@/core/helpers/page-buttons';
import DataService from '@/core/services/DataService';
import DwLoader from '@/components/DwLoader.vue';
import OptionSelector from "@/components/selectors/OptionSelector.vue";
import moment from 'moment';

export default defineComponent({
	name: 'dashboard',
	components: { DwLoader, OptionSelector },
	data() {
		return {
			records: [],
			loads: {
				advertisers: false,
				publishers: false,
				performance: false,
			},
			performanceDates: {
				dateRange: [
					moment(moment().subtract(6, 'days').format('YYYY-MM-DD') + ':00:00:00', ' YYYY-MM-DD HH:mm:ss').toDate(),
					moment(moment().format('YYYY-MM-DD') + ':23:59:59', ' YYYY-MM-DD HH:mm:ss').toDate(),
				],
				timezone: 0
			},
			advertisersDates: {},
			publishersDates: {},
			requestDataPerformance: {
				dimensions: [],
				metrics: ['cost', 'clicks', 'impressions', 'reject_events'],
				group_timeframe: 'day',
				sort_col: 'profit',
				sort_dir: 'desc',
			},
			generalPerformanceRecords: [],
			advertisersRecords: [],
			publishersRecords: [],
			chartFilter: 'cost',
			chartOptions: [
				{
					t: 'Revenue',
					v: 'cost',
				},
				{
					t: 'Clicks',
					v: 'clicks',
				},
				{
					t: 'Impressions',
					v: 'impressions',
				},
				{
					t: 'Rejections',
					v: 'reject_events',
				},
			],
		};
	},
	setup() {

		console.log('Dashboard window.location.hostname: ', window.location.hostname);

		let pageTitle = 'Dashboard - Welcome to Z2A’s publishers Dashboard';

		if(window.location.hostname === 'partners.go2app.ai') {
			pageTitle = 'Dashboard - Welcome to Go2App’s publishers Dashboard';
		} else if (window.location.hostname === 'partners.zilmtech.app') {
			pageTitle = 'Dashboard - Welcome to Zilmtech’s publishers Dashboard';
		}


		onMounted(() => {
			setCurrentPageTitle(pageTitle);
			setCurrentPageButtons(null, []);
		});
	},
	mounted() {
		this.loadPerformance();
	},
	methods: {
		dateToFormat(dt: Date): string {
			const year = dt.getFullYear().toString();
			const month = ('0' + (dt.getMonth() + 1).toString()).slice(-2);
			const day = ('0' + (dt.getDate()).toString()).slice(-2);
			const hour = ('0' + (dt.getHours()).toString()).slice(-2);
			const minute = ('0' + (dt.getMinutes()).toString()).slice(-2);

			return `${year}-${month}-${day} ${hour}:${minute}`;
		},
		async loadPerformance() {
			try {
				this.loads.performance = true;
				const payload = this.requestDataPerformance;
				if (this.performanceDates.dateRange?.length === 0) return;
				payload.date_start = this.dateToFormat(this.performanceDates.dateRange[0]);
				payload.date_end = this.dateToFormat(this.performanceDates.dateRange[1]);
				payload.timezone = this.performanceDates.timezone;
				payload.sort_dir = 'desc';
				// payload.sort_col = 'revenue';
				payload.sort_col = this.chartFilter;
				payload.is_statistics = true;

				console.log('payload: ', payload);

				// const responsePerformance = await DataService.post('/admin/reports/query', payload);
				const responsePerformance = await DataService.post('reports/query', payload);
				console.log('responsePerformance', responsePerformance);
				this.generalPerformanceRecords = responsePerformance.records;
			} catch (e) {
				console.warn('err loadPerformance:', e);
			} finally {
				this.loads.performance = false;
			}
		},
		async loadData(){
			if (this.busy){
				return;
			}
			try {
				const data = {
					keyword: '',
					page: 1,
					limit: 10,
				};
				this.busy = true;
				const resp = await DataService.get('offers/getNewlyApprovedOffers', data);
				resp.records.forEach(el => {
					el.created_at = moment(el.created_at).format("YYYY-MM-DD HH:mm");
				})
				this.records = resp.records;
				// this.pagination.total = resp.total;
			} catch(e: any) {
				// alert("something went wrong");
			} finally {
				this.busy = false;
				// this.checkedRecords = [];
			}
		}
	},
	computed: {
		busy() {
			return this.loads.advertisers || this.loads.publishers || this.loads.performance;
		},
		generalPerformance() {	
			const defaultData = {
				series: [
					{ data: [], name: this.chartFilter === 'cost' ? 'revenue' : this.chartFilter },
				],
				options: {
					yaxis: [],
					xaxis: { categories: [] },
				},
			} as any;
			const records = this.generalPerformanceRecords;
			const groupedRecordsHash: string[] = [];
			const groupedRecords: any[] = [];
			records.forEach(record => {
				//
				const { group_time } = record;
				const existingIndex = groupedRecordsHash.indexOf(group_time);
				if (existingIndex >= 0) {
					groupedRecords[existingIndex].cost.v += record.cost.v;
					groupedRecords[existingIndex].clicks.v += record.clicks.v;
					groupedRecords[existingIndex].impressions.v += record.impressions.v;
					groupedRecords[existingIndex].reject_events.v += record.reject_events.v;
				} else {
					groupedRecordsHash.push(group_time);
					groupedRecords.push(record);
				}

			});
			groupedRecords.forEach((record) => {
				if (!defaultData.options.xaxis.categories.includes(record.group_time)) {
					defaultData.options.xaxis.categories.push(record.group_time);
				}
				if(this.chartFilter === 'cost') {
					defaultData.series[0].data.push(Math.round(record.cost.v));
				}  else if(this.chartFilter === 'clicks') {
					defaultData.series[0].data.push(Math.round(record.clicks.v));
				} else if(this.chartFilter === 'impressions') {
					defaultData.series[0].data.push(Math.round(record.impressions.v));
				} else if(this.chartFilter === 'reject_events') {
					defaultData.series[0].data.push(Math.round(record.reject_events.v));
				} else {
					defaultData.series[0].data.push(Math.round(record.cost.v));
				}
			});
			return defaultData;
		},
	},
	created() {
		this.loadData();
	},
	watch: {
		performanceDates(v) {
			this.loadPerformance();
		},
	},
});


